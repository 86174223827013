<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="m-0">Programaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Gestión Bonos</li>
                  <li class="breadcrumb-item active">Programaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="row">
                <!-- Tabla de contenido -->
                <div class="col-md-12">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="tab-calendarBonos"
                        data-toggle="tab"
                        href="#calendarBonos"
                        >Calendario</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="tab-tablaBonos"
                        data-toggle="tab"
                        href="#tablaBonos"
                        >Tabla</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active show" id="calendarBonos">
                      <section class="col-12">
                        <div class="card-header pt-2 pb-2">
                          <div class="row">
                            <div class="col-md-11"></div>
                            <div class="col-md-1">
                              <div class="btn-group float-right">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#modal-form-programacion"
                                  @click="
                                    abrirModal('Crear', null) && limpiar()
                                  "
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-11">
                              <label for="sit"><h5>Sitio</h5></label>
                              <div class="mt-0">
                                <v-select
                                  :class="
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : ''
                                  "
                                  v-model="sit"
                                  placeholder="Sitio"
                                  label="nombre"
                                  class="form-control form-control-sm p-0 col-md-5"
                                  :options="listasForms.sitios"
                                ></v-select>
                              </div>
                              <div class="mt-3 ">
                                <button
                                  type="button"
                                  class="btn btn-sm bg-navy p-1 col-md-3"
                                  @click="getCalendar()"
                                >
                                  Filtrar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body p-0">
                          <div class="row">
                            <div class="col-md-3">
                              <div
                                class="form-group"
                                style="text-align: left"
                              ></div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <FullCalendar :options="calendarOptions" />
                            </div>
                          </div>
                          <div style="display: none">
                            <button
                              type="button"
                              data-toggle="modal"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.bonosProgramacion.edit'
                                )
                              "
                              data-target="#modal-form-programacion"
                              ref="detalle_programacion"
                            ></button>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div class="tab-pane" id="tablaBonos">
                      <div class="card">
                        <div class="card-body p-0">
                          <table
                            class="
                              table table-bordered table-striped table-hover table-sm                          
                            "
                          >
                            <thead align="center">
                              <tr>
                                <th class="text-center">
                                  <label for="">Sitio</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.nSitio"
                                    placeholder="Sitio"
                                    @change="getIndex()"
                                  />
                                </th>
                                <th class="text-center">
                                  <label for="">Sitio Destino</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.sitio_destino"
                                    placeholder="Sitio Destiono"
                                    @change="getIndex()"
                                  />
                                </th>
                                <th class="text-center">
                                  <label for="">Tipo Bono</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="obj_tipo_bono"
                                    placeholder="Tipo Bono"
                                    label="descripcion"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.tipos_bonos"
                                    @input="selectTiposBonos()"
                                  ></v-select>
                                </th>
                                <th class="text-center">
                                  <label for="">Fecha Inicial</label>
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="filtros.fecha_ini"
                                    @input="getIndex()"
                                  />
                                </th>
                                <th class="text-center">
                                  <label for="">Fecha Final</label>
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="filtros.fecha_fin"
                                    @input="getIndex()"
                                  />
                                </th>
                                <th class="text-center">
                                  <label for="">Temporada</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="obj_temporada"
                                    placeholder="Temporada"
                                    label="descripcion"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.temporadas"
                                    @input="selectTemporada()"
                                  ></v-select>
                                </th>
                                <th class="text-center">
                                  <label for="">Tipo Ruta</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="obj_tipo_ruta"
                                    placeholder="Tipo Ruta"
                                    label="descripcion"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.tipos_ruta"
                                    @input="selectTipoRuta()"
                                  ></v-select>
                                </th>
                                <th class="pb-3">Monto</th>
                                <th>
                                  <label for="estado">Estado</label>
                                  <select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="filtros.estado"
                                    @change="getIndex()"
                                    placeholder="Estado"
                                    class="form-control form-control-sm p-0"
                                  >
                                    <option value="0">Todos...</option>
                                    <option value="1">Pendiente</option>
                                    <option value="2">Autorizado</option>
                                  </select>
                                </th>
                                <th>Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in detProgramacionBonos.data"
                                :key="item.id"
                              >
                                <td class="text-center">
                                  {{ item.sitio ? item.sitio.nombre : "" }}
                                </td>
                                <td class="text-center">
                                  {{
                                    item.sitio_destino
                                      ? item.sitio_destino.nombre
                                      : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ item.nTipoBono }}
                                </td>
                                <td class="text-center">
                                  {{ item.fecha_ini }}
                                </td>
                                <td class="text-center">
                                  {{ item.fecha_fin }}
                                </td>
                                <td class="text-center">
                                  {{ item.nTemporada }}
                                </td>
                                <td class="text-center">
                                  {{ item.nTipoRuta }}
                                </td>
                                <td class="text-center">
                                  {{
                                    item.monto
                                      ? "$ " +
                                        item.monto.toLocaleString("es-ES")
                                      : "---"
                                  }}
                                </td>
                                <td class="text-center">
                                  <span
                                    :class="
                                      item.estado == 1
                                        ? 'badge shadow badge badge-pill badge-warning'
                                        : item.estado == 2
                                        ? 'badge shadow badge badge-pill badge-success'
                                        : ''
                                    "
                                  >
                                    {{ item.nEstado ? item.nEstado : "---" }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    @click="Editar(item)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="card-footer">
                          <div
                            class="float-left"
                            v-if="detProgramacionBonos.total"
                          >
                            <p>
                              Mostrando del
                              <b>{{ detProgramacionBonos.from }}</b> al
                              <b>{{ detProgramacionBonos.to }}</b> de un total:
                              <b>{{ detProgramacionBonos.total }}</b> Registros
                            </p>
                          </div>
                          <div class="float-left" v-else>
                            <p>
                              <span class="badge badge-dark"
                                >No hay registros para mostrar</span
                              >
                            </p>
                          </div>
                          <pagination
                            :data="detProgramacionBonos"
                            @pagination-change-page="getIndex"
                            :limit="5"
                            align="right"
                          ></pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal de filtro por fila -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ProgramacionBonoModal ref="ProgramacionBonoModal" />
  </div>
</template>

<style>
.swal-wide {
  width: 1200px !important;
}
</style>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import ProgramacionBonoModal from "./ProgramacionBonoModal";
import Loading from "../../../../components/Loading";

export default {
  name: "ProgramacionBonoIndex",
  components: {
    FullCalendar,
    vSelect,
    ProgramacionBonoModal,
    pagination,
    Loading,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [
          listGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
        ],
        initialView: "dayGridMonth",
        // initialView: "listWeek",
        dateClick: this.handleDateClick,
        locale: esLocale,
        selectable: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "listDay,timeGridDay,dayGridWeek,dayGridMonth",
        },
        events: [],
        eventClick: this.EditarModal,
        eventColor: "green",
      },
      form: {
        sitio_id: null,
        fecha_ini: null,
        fecha_fin: null,
        tipo_bono_id: null,
        observacion: null,
      },
      filtros: {
        nSitio: null,
        sitio_destino: null,
        temporada: null,
        tipo_ruta: null,
        tipo_bono: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      },
      detProgramacionBonos: {},
      modal: {
        title: "",
        accion: "",
      },
      obj_tipo_bono: {},
      obj_temporada: {},
      obj_tipo_ruta: {},
      cargando: false,
      sit: {},
      sitio: {},
      listasForms: {
        sitios: [],
        tipos_bonos: [],
        tipos_ruta: [],
        temporadas: [],
      },
    };
  },
  validations() {
    return {
      form: {
        sitio_id: {
          required,
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        tipo_bono_id: {
          required,
        },
        observacion: {
          required,
        },
      },
    };
  },
  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("api/hidrocarburos/detProgramacionBono?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.detProgramacionBonos = await response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      await this.getTipoBono();
      await this.getTemporadas();
      await this.getTiposRuta();
      await this.getCalendar();
    },

    async getCalendar() {
      let me = this;
      axios
        .get("api/hidrocarburos/detProgramacionBono/calendar", {
          params: me.sit,
        })
        .then((response) => {
          me.calendarOptions.events = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSitio() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    getTipoBono() {
      axios.get("/api/lista/194").then((response) => {
        this.listasForms.tipos_bonos = response.data;
      });

      /* axios.get("/api/hidrocarburos/tiposBonos/lista").then((response) => {
        this.listasForms.tipos_bonos = response.data;
      }); */
    },

    getTiposRuta() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipos_ruta = response.data;
      });
    },

    getTemporadas() {
      axios.get("/api/lista/121").then((response) => {
        this.listasForms.temporadas = response.data;
      });
    },

    filtrarSitio() {
      this.sitio_id = this.listasForms.sitio ? this.listasForms.sitio.id : null;
    },

    abrirModal(accion, clickInfo = null, numeracion) {
      this.getSitio();
      this.getTipoBono();
      this.getTiposRuta();
      this.getTemporadas();
      this.modal.accion = accion;
      this.modal.title = accion + " Programacion Bono";

      if (accion == "Editar") {
        if (numeracion == 1) {
          this.pro = clickInfo.event.extendedProps.programacion;
        } else {
          this.pro = clickInfo;
        }
        this.$refs.ProgramacionBonoModal.llenar_modal(this.pro, 1);
        this.$refs.detalle_programacion.click();
        this.form.id = this.pro.id;
        //this.form.tipo_bono_id = this.obj_tipo_bono.numeracion;

        this.sitio = {
          id: this.pro.sitio.id,
          nombre: this.pro.sitio.nombre,
        };

        this.form.sitio_id = this.sitio.id;

        this.sitio_destino = {
          id: this.pro.sitio_destino ? this.pro.sitio_destino.id : null,
          nombre: this.pro.sitio_destino ? this.pro.sitio_destino.nombre : null,
        };

        this.form.sitio_destino_id = this.sitio_destino.id;
        this.form.fecha_ini = this.pro.fecha_ini;
        this.form.fecha_fin = this.pro.fecha_fin;
        this.form.observacion = this.pro.observacion;
      } else {
        this.form = { sitio_id: null, tipo_bono_id: null };
        this.sitio = [];
        this.obj_tipo_bono = [];
        this.$refs.ProgramacionBonoModal.llenar_modal(this.pro, null);
      }
    },

    EditarModal(clickInfo) {
      this.abrirModal("Editar", clickInfo, 1);
    },

    Editar(item) {
      this.abrirModal("Editar", item, 2);
    },

    limpiar() {
      this.form.id = null;
      this.form.sitio_id = null;
      this.form.fecha_ini = null;
      this.form.fecha_fin = null;
      this.form.tipo_bono_id = null;
      this.form.observacion = null;
      this.sitio = [];
      this.obj_tipo_bono = [];
    },

    selectTiposBonos() {
      this.filtros.tipo_bono = null;
      if (this.obj_tipo_bono) {
        this.filtros.tipo_bono = this.obj_tipo_bono.numeracion;
      }
      this.getIndex();
    },

    selectTemporada() {
      this.filtros.temporada = null;
      if (this.obj_temporada) {
        this.filtros.temporada = this.obj_temporada.numeracion;
      }
      this.getIndex();
    },

    selectTipoRuta() {
      this.filtros.tipo_ruta = null;
      if (this.obj_tipo_ruta) {
        this.filtros.tipo_ruta = this.obj_tipo_ruta.numeracion;
      }
      this.getIndex();
    },
  },

  mounted() {
    this.getIndex();
    this.getSitio();
  },
};
</script>
